import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import { LoadingOverlay } from "./components/LoadingOverlay";

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <ReactKeycloakProvider
        initOptions={{ onLoad: "login-required", checkLoginIframe: false }}
        authClient={keycloak}
        LoadingComponent={<LoadingOverlay />}
      >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactKeycloakProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
