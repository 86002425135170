import { Geometry } from "geojson";
import create from "zustand";

import { Span, SpansResponseProperties } from "../features/layers/types/SpansResponse";
import { RightOfWayOverride, RightOfWayOverridesResponse } from "../types/responses";

export type RightOfWayOverridesStore = {
  byParcelId: ById;
  bySpanId: ById;
  getByParcelId: (id: string) => Maybe<RightOfWayOverride>;
  getBySpanId: (id: string) => Maybe<RightOfWayOverride>;
  localOverrides: Record<string, Span>;
  overrides: Nullable<RightOfWayOverride[]>;
  resetLocalOverrides: () => void;
  setData: (data: RightOfWayOverridesResponse) => void;
  setLocalOverrides: ({
    rowOverrideId,
    polygons,
  }: {
    rowOverrideId: string;
    polygons: { spanId: string; polygon: Geometry }[];
  }) => void;
  removeOverride: (id: string, isSpan: boolean) => void;
};

type ById = Record<string, RightOfWayOverride>;

const INITIAL_STATE = {
  localOverrides: {},
  overrides: null,
  bySpanId: {},
  byParcelId: {},
};

export const useRightOfWayOverridesStore = create<RightOfWayOverridesStore>((set, get) => ({
  ...INITIAL_STATE,
  resetStore: () => set({ ...INITIAL_STATE }),
  setData: (overridesResponse) => set(mapOverridesResponse(overridesResponse, get().bySpanId, get().byParcelId)),
  setLocalOverrides: ({ polygons }) => {
    if (!polygons || !polygons.length) {
      return;
    }
    const localOverrides = { ...get().localOverrides };
    polygons.forEach((override) => {
      localOverrides[override.spanId] = {
        type: "Feature",
        geometry: override.polygon,
        properties: { id: override.spanId } as SpansResponseProperties,
      };
    });
    set({ localOverrides });
  },
  resetLocalOverrides: () => set({ localOverrides: {} }),
  getBySpanId: (id: string) => get().bySpanId?.[id],
  getByParcelId: (id: string) => get().byParcelId?.[id],
  removeOverride: (id, isSpan) => {
    if (isSpan) {
      const spanById = { ...get().bySpanId };
      spanById?.[id] &&
        set({
          bySpanId: Object.keys(spanById)
            .filter((key) => key !== id)
            .reduce((obj, key) => {
              return Object.assign(obj, {
                [key]: spanById[key],
              });
            }, {}),
        });
    } else {
      const parcels = { ...get().byParcelId };
      parcels?.[id] &&
        set({
          byParcelId: Object.keys(parcels)
            .filter((key) => key !== id)
            .reduce((obj, key) => {
              return Object.assign(obj, {
                [key]: parcels[key],
              });
            }, {}),
        });
    }
  },
}));

export default useRightOfWayOverridesStore;

const mapOverridesResponse = ({ overrides }: RightOfWayOverridesResponse, oldSpans: ById, oldParcels: ById) => {
  const bySpanId = oldSpans ? { ...oldSpans } : {};
  const byParcelId = oldParcels ? { ...oldParcels } : {};
  overrides?.forEach((override) => {
    if (override.parcelId) {
      byParcelId[override.parcelId] = override;
    } else if (override.spanId) {
      bySpanId[override.spanId] = override;
    }
  });

  return {
    overrides,
    bySpanId,
    byParcelId,
  };
};
