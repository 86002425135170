export enum PARCEL_STATUS {
  REFUSED = "REFUSED",
  CONCERNED = "CONCERNED",
  APPROVED = "APPROVED",
  NOT_ASSIGNED = "NOT_ASSIGNED",
  NOTIFIED = "NOTIFIED",
}

export type ParcelsResponseProperties = {
  address: string;
  city: string;
  id: string;
  mapRef: unknown;
  ownerFirstName: string;
  ownerLastName: string;
  parcelId: number;
  state: string;
  zip: string;
  status: PARCEL_STATUS;
  note: Nullable<string>;
  contactEmail: Nullable<string>;
  contactPhone: Nullable<string>;
  ownerSameAsOnSiteContact: boolean;
  onSiteOwnerFirstName: string;
  onSiteOwnerLastName: string;
  onSiteOwnerEmail: string;
  onSiteOwnerPhone: string;
};

export type Parcel = GeoJSON.Feature<GeoJSON.Geometry, ParcelsResponseProperties>;

export type ParcelsResponse = GeoJSON.FeatureCollection<GeoJSON.Geometry, ParcelsResponseProperties>;

export type UpdateParcelContactInfoDto = {
  ownerFirstName: string;
  ownerLastName: string;
  contactEmail: string;
  contactPhone: string;
  ownerSameAsOnSiteContact: boolean;
  onSiteOwnerFirstName: string;
  onSiteOwnerLastName: string;
  onSiteOwnerContactEmail: string;
  onSiteOwnerContactPhone: string;
  status: PARCEL_STATUS;
  note: string;
};

export type UpdateParcelStatus = {
  status: string;
  parcelIds: Nullable<Array<string>>;
};
