import create from "zustand";

export enum MessageType {
  Success = "success",
  Error = "error",
}

export type MessagesStore = {
  errorMessage: string | null;
  type: MessageType | null;
  setErrorMessage: (errorMessage: string | null, type?: MessageType) => void;
  resetStore: () => void;
};

const INITIAL_STATE = {
  errorMessage: null,
  type: null,
};

export const useMessageStore = create<MessagesStore>((set, get) => ({
  ...INITIAL_STATE,

  setErrorMessage: (message: string | null, messageType: MessageType = MessageType.Error) =>
    set(({ errorMessage, type }) => ({ errorMessage: message, type: messageType ?? type })),
  resetStore: () => {
    set({ ...INITIAL_STATE });
  },
}));

export default useMessageStore;
