import { FC } from "react";
import {
  LoadingOverlayStyled,
  LoadingModal,
  LoadingMessage,
  LoaderWrapper,
  AnimatedLoader,
  LoadingWrapper,
} from "./LoadingOverlay.styled";

interface LoadingOverlayProps {
  hidden?: boolean;
  message?: string;
}

export const LoadingOverlay: FC<LoadingOverlayProps> = ({ hidden = false, message = "LOADING" }) => {
  if (hidden) {
    return <></>;
  }

  return (
    <LoadingOverlayStyled data-testid="loading-overlay">
      <LoadingWrapper />
      <LoadingModal>
        <LoadingMessage>{message}</LoadingMessage>
        <LoaderWrapper>
          <AnimatedLoader />
        </LoaderWrapper>
      </LoadingModal>
    </LoadingOverlayStyled>
  );
};
