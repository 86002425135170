export const WHITE = "#FFF";

export const ERROR_RED = "#FF0000";
export const INFO_ORANGE = "#FFB380";

// TODO  find better naming system
export const BLUE_5 = "#0067FF";

export const PURPLE_4 = "#2B2744";
export const PURPLE_5 = "#272340";
