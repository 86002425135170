import L from "leaflet";
import create from "zustand";

import { FORECAST_QUERY_PARAMS } from "../../constants/navigation";
import { getQueryParamValue, hasQueryParam } from "../../utils/navigationUtils";
import { INITIAL_STATE } from "./MapStore.constants";
import {
  AdditionalLayer,
  AdditionalOverlay,
  ADDITIONAL_LAYER_TYPE,
  layerCategories,
  LAYER_SWITCH_CONTROL,
  MapStoreState,
  OVERLAY_LAYERS,
} from "./MapStore.types";

import { VectorLayers } from "../../types/responses";

const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN_MAP;
const REACT_APP_MAPBOX_TOPOGRAPHIC_URL = process.env.REACT_APP_MAPBOX_TOPOGRAPHIC_URL;

export const useMapStore = create<MapStoreState>((set, get) => ({
  ...INITIAL_STATE,
  setOverlayMapLayerVisibility: ({ name, value }) => {
    const overlayMapLayers = { ...get().overlayMapLayers };
    overlayMapLayers[name as OVERLAY_LAYERS] = value as boolean;
    set({ overlayMapLayers });
  },
  setMap: (map) => {
    if (!map) {
      return;
    }
    map.boxZoom.disable();
    set({ map });
    get().initMapHandlers();
  },
  invalidateMapSize: () => {
    const leafletMap = get().map;
    if (!leafletMap) {
      return;
    }
    leafletMap.invalidateSize();
  },

  setZoomControlStatus: (isZoomEnabled) => {
    set({ zoomControlStatus: isZoomEnabled });
    const leafletMap = get().map;
    if (!leafletMap) {
      return;
    }

    if (isZoomEnabled) {
      leafletMap.scrollWheelZoom.enable();
      leafletMap.touchZoom.enable();
      leafletMap.doubleClickZoom.enable();
    } else {
      leafletMap.scrollWheelZoom.disable();
      leafletMap.touchZoom.disable();
      leafletMap.doubleClickZoom.disable();
    }
  },
  setImagerySourcesData: (imagerySources) => {
    if (!imagerySources || !Object.keys(imagerySources).length) {
      return;
    }
    const selectedImagerySourceId = hasQueryParam(FORECAST_QUERY_PARAMS.SOURCE_ID)
      ? getQueryParamValue(FORECAST_QUERY_PARAMS.SOURCE_ID)
      : Object.keys(imagerySources).find((key) => imagerySources[key].isDefault);
    set({ imagerySources, selectedImagerySourceId: selectedImagerySourceId ?? null });
  },
  setSelectedImagerySource: (id) => {
    if (!id || id === get().selectedImagerySourceId) {
      return;
    }
    set({
      selectedImagerySourceId: id,
    });
  },
  setBaseLayerName: (name: string) => set({ baseLayer: name }),
  initMapHandlers: () => {
    const leafletMap = get().map;
    if (!leafletMap) {
      return;
    }
    leafletMap.on("zoomend", () => {
      L.Util.requestAnimFrame(() => {
        set({ currentZoomLevel: leafletMap.getZoom() });
      });
    });

    leafletMap.on("baselayerchange", (ev: L.LayersControlEvent) => {
      // imagery sources base layer change listener
      const imagerySourcesNames = Object.keys(get().imagerySources).map((source) => get().imagerySources[source].name);
      if (imagerySourcesNames.includes(ev.name)) {
        const selectedId = Object.keys(get().imagerySources).find(
          (source) => get().imagerySources[source].name === ev.name
        );
        selectedId && set({ selectedImagerySourceId: selectedId });
      }
    });

    leafletMap.on("moveend", () => {
      L.Util.requestAnimFrame(() => get().setCurrentMapBounds(leafletMap.getBounds()));
    });
  },

  closeMapPopup: () => get().map?.closePopup(),
  toggleLayerControl: (forceClose?) => set({ layerControlVisible: forceClose ? false : !get().layerControlVisible }),
  setOverlaySwitchState: ({ name, value }) => {
    const overlaySwitchControl = { ...get().overlaySwitchControl };
    overlaySwitchControl[name as LAYER_SWITCH_CONTROL].checked = value;
    get().closeMapPopup();
    set({ overlaySwitchControl });
  },
  setAdditionalOverlays: (data: Record<string, AdditionalOverlay>) => {
    if (!data) {
      return;
    }
    const additionalOverlayOpacity: Record<string, number> = {};
    let defaultOverlay;
    data["TOPOGRAPHIC"].url = `${REACT_APP_MAPBOX_TOPOGRAPHIC_URL}${MAPBOX_ACCESS_TOKEN}`;
    Object.keys(data).forEach((key) => {
      additionalOverlayOpacity[key] = Number(data[key].opacityValue);
      if (data[key].isDefault) {
        defaultOverlay = key;
      }
    });

    set({ additionalOverlayList: data, additionalOverlayOpacity });
    defaultOverlay && get().setAdditionalOverlay(defaultOverlay);
  },
  setAdditionalOverlay: (id: string) => {
    if (!id || id === get().selectedAdditionalOverlay) {
      return;
    }
    get().closeMapPopup();
    set({ selectedAdditionalOverlay: id });
  },
  setOpacityOverlayByKey: (key: string, value: number) => {
    const additionalOverlayOpacity = { ...get().additionalOverlayOpacity };
    if (!additionalOverlayOpacity || additionalOverlayOpacity[key] === null) {
      return;
    }
    additionalOverlayOpacity[key] = value ? Number(value) : 0;
    set({ additionalOverlayOpacity });
  },
  setHoveredObjectId: (id: Nullable<string>) => {
    if (get().hoveredObjectId === id) {
      return;
    }
    set({ hoveredObjectId: id });
  },
  setCurrentMapBounds: (bounds) => set({ currentMapBounds: bounds }),
  setVectorLayerVisibility: ({ key, value }) => {
    const vectorLayers = { ...get().vectorLayers };
    if (!key || !vectorLayers?.[key]) {
      return;
    }
    key !== ADDITIONAL_LAYER_TYPE.HAZARD_TREE && get().closeMapPopup();
    vectorLayers[key].checked = value as boolean;
    set({ vectorLayers });
  },
  setVectorLayers: (layers: Nullable<VectorLayers>) => {
    if (!layers?.layers) {
      return;
    }
    const layersRecord: Record<string, AdditionalLayer> = {};
    layers.layers
      .filter((layer) => layer.visibilityPolicy === "ADDITIONAL")
      .forEach(
        (layer) =>
          (layersRecord[layer.code] = {
            ...layer,
            category: layerCategories[layer.code as ADDITIONAL_LAYER_TYPE],
            checked: false,
          })
      );
    const urbanLayer = layers.layers.find((layer) => layer.code === ADDITIONAL_LAYER_TYPE.URBAN);
    set({ vectorLayers: layersRecord, urbanLayerId: urbanLayer ? urbanLayer.id : null });
  },
  setLatestObjectIdSelectedForMapAnimation: (latestObjectIdSelectedForMapAnimation) => {
    set({ latestObjectIdSelectedForMapAnimation, latestObjectIdSelectedForMapAnimationTimestamp: Date.now() });
  },
  getLatestObjectSelectedForMapAnimation: () => ({
    id: get().latestObjectIdSelectedForMapAnimation,
    timestamp: get().latestObjectIdSelectedForMapAnimationTimestamp,
  }),

  setMapLayerOpacity: (opacity: number) => {
    const current = get().mapOpacityLayerControl;
    if (!current) {
      return;
    }
    current.opacity = opacity;
    set({ mapOpacityLayerControl: Object.assign({}, current) });
  },
  toggleOpacityMapLayer: () => {
    const current = get().mapOpacityLayerControl;
    if (!current) {
      return;
    }
    current.checked = !current.checked;
    set({ mapOpacityLayerControl: Object.assign({}, current) });
  },
  resetMapState: () => {
    set({
      map: INITIAL_STATE.map,
    });
  },
}));
