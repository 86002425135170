export const theme = {
  colors: {
    background: "var(--colors-background)",
    white: "var(--colors-white)",
    black: "var(--colors-black)",
    transparent: "var(--colors-transparent)",
    froth: "var(--colors-froth)",
    whiteSmoke: "var(--colors-white-smoke)",
    lynxWhite: "var(--colors-lynx-white)",
    cumulus: "var(--colors-cumulus)",
    darkCave: "var(--colors-dark-cave)",
    abbey1: "var(--colors-abbey-1)",
    abbey2: "var(--colors-abbey-2)",
    teal1: "var(--colors-teal-1)",
    teal2: "var(--colors-teal-2)",
    teal3: "var(--colors-teal-3)",
    mineShaft1: "var(--colors-mine-shaft-1)",
    mineShaft2: "var(--colors-mine-shaft-2)",
    whiteSand: "var(--colors-white-sand)",
    codGray1: "var(--colors-cod-gray-1)",
    codGray2: "var(--colors-cod-gray-2)",
    balticSea: "var(--colors-baltic-sea)",
    gallery: "var(--colors-gallery)",
    geyser: "var(--colors-geyser)",
    blizzardBlue: "var(--colors-blizzard-blue)",
    frenchGray: "var(--colors-french-gray)",
    trout: "var(--colors-trout)",
    smaltBlue: "var(--colors-smalt-blue)",
    catskillWhite: "var(--colors-catskill-white)",
    athensGray: "var(--colors-athens-gray)",
    waterloo: "var(--colors-waterloo)",
    easternBlue: "var(--colors-eastern-blue)",
    athensGray2: "var(--colors-athens-gray-2)",
    alabaster: "var(--colors-alabaster)",
    manatee: "var(--colors-manatee)",
    powderBlue: "var(--colors-powder-white)",
    trout2: "var(--colors-trout-2)",
    java: "var(--colors-java)",
    blueChill: "var(--colors-blue-chill)",
    alabaster2: "var(--colors-alabaster-2)",
    oysterBay: "var(--colors-oyster-bay)",
    morningGlory: "var(--colors-morning-glory)",
    riverBad: "var(--colors-river-bad)",
    mercury: "var(--colors-mercury)",
    burntSienna: "var(--colors-burnt-sienna)",
    fairPink: "var( --colors-fair-pink)",
    tuftBush: "var(--colors-tuft-bush)",
    persimmon: "var(--colors-persimmon)",
    pastelGreen: "var(--colors-pastel-green)",
    lightingYellow: "var(--colors-lighting-yellow)",
    iceberg: "var(--colors-iceberg)",
    cyan: "var(--colors-cyan)",
    mineralGreen: "var(--colors-mineral-green)",
    seashell: "var(--colors-seashell)",
    priorityViolation: "var(--colors-red)",
    priorityCritical: "var(--colors-crusta)",
    priorityHigh: "var(--colors-gold-tips)",
    priorityMedium: "var(--colors-java-2)",
    priorityLow: "var(--colors-vida-loca)",
    capeCod: " var(--colors-cape-cod)",
    botticelli: "var(--colors-botticelli)",
    osloGray: "var(--colors-oslo-gray)",
    broom: "var(--colors-broom)",
    supernova: "var(--colors-supernova)",
    capeCod2: "var(--colors-cape-cod-2)",
    fountainBlue: "var(--colors-fountain-blue)",
    ebb: "var(--colors-ebb)",
    notePassive: "var(--parcel-note-passive)",
    noteDisabled: "var(--parcel-note-disabled)",
    outerSpace: "var(--colors-outer-space)",
    nevada: "var(--colors-nevada:)",
    osloGray2: "var(--colors-oslo-gray-2)",
    silver: "var(--color-silver)",
    disabledColor: "var(--colors-disabled-color)",
    gallery2: "var(--colors-gallery-2)",
    ziggurat: "var(--colors-ziggurat)",
    atoll: "var(--colors-atoll)",
    shakespeare: "var(--colors-shakespeare)",
    bonJour: "var(--colors-bon-jour)",
    alabaster3: "var(--colors-alabaster-3)",
    mystic: "var(--color-mystic)",
    whiteIce: "var(--colors-white-ice)",
    alto: "var(--colors-alto)",
    paleSky: "var(--colors-pale-sky)",
    linkWater: "var(--colors-link-water)",
    tundora: "var(--colors-tundora)",
  },
};
