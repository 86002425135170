import create from "zustand";

import { EMPTY_FN } from "../constants";
import { GetMaintenanceAreasByExtentResponseDto, MaintenanceAreaWithSpansDto } from "../types/responses";
import { randomizeColor } from "../utils/colors";

const FROM_COLOR = [8, 2, 112];

const TO_COLOR = [0, 212, 255];

export type BySpanId = Record<string, { name: string; id: string; color?: string }>;

export type MaintenanceAreaStore = {
  toggleLayer: () => void;
  isLayerVisible: boolean;
  data: Nullable<GetMaintenanceAreasByExtentResponseDto>;
  setData: (data: Nullable<GetMaintenanceAreasByExtentResponseDto>) => void;
  bySpanId: Nullable<BySpanId>;
  colorByMAId: Record<string, string>;
  updateData: (newMA: MaintenanceAreaWithSpansDto) => void;
  assignMaToSpans: (maId: string, spanIds: string[]) => void;
};

const INITIAL_STATE = {
  toggleLayer: EMPTY_FN,
  isLayerVisible: false,
  data: null,
  bySpanId: null,
  colorByMAId: {},
};

export const useMaintenanceAreaStore = create<MaintenanceAreaStore>((set, get) => ({
  ...INITIAL_STATE,
  toggleLayer: () => {
    const isLayerVisible = !get().isLayerVisible;
    set({ isLayerVisible });
    if (!isLayerVisible) {
      get().setData(null);
    }
  },
  setData: (data) => {
    const bySpanId: BySpanId = {};
    const colorByMAId = get().colorByMAId;
    if (data?.maintenanceAreas?.length) {
      data.maintenanceAreas.forEach((area) => {
        if (!colorByMAId[area.maintenanceArea.id]) {
          colorByMAId[area.maintenanceArea.id] = randomizeColor(FROM_COLOR, TO_COLOR);
        }
        area.color = colorByMAId[area.maintenanceArea.id];
        area.spanIds.forEach((span) => {
          bySpanId[span] = { color: area.color, name: area.maintenanceArea.name, id: area.maintenanceArea.id };
        });
      });
    }
    set({ data, bySpanId, colorByMAId });
  },
  updateData: (newMA) =>
    set(({ data }) => {
      const updatedMaArray = [...data?.maintenanceAreas!, { ...newMA, color: randomizeColor(FROM_COLOR, TO_COLOR) }];
      const updatedData = { maintenanceAreas: [...updatedMaArray] };
      return { data: updatedData };
    }),
  assignMaToSpans: (maId, spanIds) =>
    set(({ data }) => {
      const updatedData =
        data?.maintenanceAreas.map((item) => {
          if (item.maintenanceArea.id === maId) {
            return {
              ...item,
              spanIds,
            };
          }
          return item;
        }) ?? [];
      return { data: { maintenanceAreas: updatedData } };
    }),
}));

export default useMaintenanceAreaStore;
