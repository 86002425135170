import { FC } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { getLandingRoute } from "../../routes";
import { useAuthStore } from "../../stores";

interface Props extends RouteProps {
  redirectLoggedInUsers?: boolean;
}

export const PublicRoute: FC<Props> = ({ component, redirectLoggedInUsers = false, ...rest }) => {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);

  if (isAuthenticated && redirectLoggedInUsers) {
    return <Redirect push to={getLandingRoute()} />;
  }

  return <Route component={component} {...rest} />;
};
