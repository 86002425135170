import { QueryClient } from "react-query";

const ONE_HOUR_MS = 1000 * 60 * 60;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: ONE_HOUR_MS,
      cacheTime: ONE_HOUR_MS,
    },
  },
});
