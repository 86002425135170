import create from "zustand";

import { DrawerStore } from "./DrawerStore.types";

const INITIAL_STATE = {
  stack: [],
};

export const useDrawerStore = create<DrawerStore>((set, get) => ({
  ...INITIAL_STATE,
  push: (element, isInnerLevel?) => {
    if (!isInnerLevel) {
      get().clearStack();
    }
    const currentStack = get().stack;
    if (currentStack.find((item) => item.id === element.id)) {
      return;
    }

    set({ stack: currentStack.concat(element) });
  },
  pop: () => {
    const currentStack = get().stack;
    currentStack?.pop();
    set({ stack: Object.assign([], currentStack) });
  },
  clearStack: () => {
    set({ ...INITIAL_STATE });
  },
  getLastElement: () => {
    const currentStack = get().stack;
    if (!currentStack?.length) {
      return undefined;
    }
    return currentStack[currentStack.length - 1];
  },
}));

export default useDrawerStore;
