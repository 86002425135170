import create from "zustand";

export enum LegendStateType {
  PARCEL = "Parcel",
  WORK_TYPE = "WorkType",
  HYDROLOGY = "Hydrology",
  TREE_RECON = "TreeReconn",
  DANGER_TREE = "DangerTrees",
}

const INITIAL_VALUES: Record<LegendStateType, LegendState> = {
  Parcel: { expanded: true },
  WorkType: { expanded: true },
  Hydrology: { expanded: true },
  TreeReconn: { expanded: true },
  DangerTrees: { expanded: true },
};

export type LegendState = {
  expanded: boolean;
};

export type LegendManagementStore = {
  legendState: Record<LegendStateType, LegendState>;
  toggleExpand: (legendType: LegendStateType, forceState?: boolean) => void;
};

const INITIAL_STATE = {
  legendState: INITIAL_VALUES,
};

const useLegendManagementStore = create<LegendManagementStore>((set, get) => ({
  ...INITIAL_STATE,
  toggleExpand: (legendType, forceState) => {
    const legendState = { ...get().legendState };
    if (!legendState) {
      return;
    }
    Object.keys(legendState).forEach((key) => {
      legendState[key as LegendStateType].expanded =
        key === legendType.toString()
          ? forceState === undefined
            ? !legendState[key as LegendStateType].expanded
            : forceState
          : false;
    });
    set({ legendState });
  },
}));

export { useLegendManagementStore };
