export enum FORECAST_QUERY_PARAMS {
  "REGION_ID" = "regionId",
  "FEEDER_ID" = "feederId",
  "ENCROACHMENT_ID" = "encroachmentId",
  "SOURCE_ID" = "sourceId",
  "SPAN_ID" = "spanId",
  "STRUCTURE_ID" = "structureId",
  "VISIBLE_DRAWER" = "visibleDrawer",
  "PARCEL_ID" = "parcelId",
  "SPAN_RISK_PRIORITY" = "spanRiskPriority",
}
