import { User } from "../stores/AuthStore";

class AuthHelper {
  static USER_KEY = "USER_KEY";

  static saveUser(user: User) {
    localStorage.setItem(this.USER_KEY, JSON.stringify(user));
  }

  static removeUser() {
    localStorage.removeItem(this.USER_KEY);
  }

  static getUser(): User | null {
    const user = localStorage.getItem(this.USER_KEY);
    if (!user) {
      return null;
    }
    try {
      return JSON.parse(user);
    } catch (error) {
      return null;
    }
  }
}

export default AuthHelper;
