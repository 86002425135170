import create from "zustand";

export type LeftControlStoreState = {
  toggled: boolean;
  setToggled: (toggle: boolean) => void;
};

const useLeftControlStore = create<LeftControlStoreState>((set) => ({
  toggled: true,
  setToggled: (toggle) => {
    set({ toggled: toggle });
  },
}));

export { useLeftControlStore };
