import { useEffect, Suspense, lazy } from "react";
import { Switch, Redirect, Route, useLocation } from "react-router-dom";
import { QueryClientProvider } from "react-query";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { ThemeProvider } from "styled-components";
import { useKeycloak } from "@react-keycloak/web";
import { StompSessionProvider } from "react-stomp-hooks";

import { PrivateRoute } from "./components/PrivateRoute";
import { USER_ROLES } from "./stores/AuthStore";
import {
  getLandingRoute,
  getForecastRoute,
  getErrorRoute,
  getAdminRoute,
  getStarCommandRoute,
  plansRoute,
  createPlanRoute,
  workOrderRoute,
} from "./routes";
import { logError } from "./services";
import { PublicRoute } from "./components/PublicRoute";
import "./App.css";
import { theme } from "./theme";
import ApiClient from "./utils/apiClient";
import useAuthStore from "./stores/AuthStore";
import { EnvironmentVariablesCheck } from "./components/EnvironmentVariablesCheck";
import { queryClient } from "./reactQueryAppClient";
import { LoadingOverlay } from "./components/LoadingOverlay";

import "@reach/tooltip/styles.css";
import { notificationUrl } from "./api";

const Header = lazy(() => import("./components/Header").then((module) => ({ default: module["Header"] })));
const GeneralError = lazy(() => import("./pages/GeneralError").then((module) => ({ default: module["GeneralError"] })));
const AddEditPlan = lazy(() =>
  import("./features/planning/components/AddEditPlan").then((module) => ({ default: module["AddEditPlan"] }))
);
const MapScreen = lazy(() => import("./pages/MapScreen"));
const Admin = lazy(() => import("./pages/Admin"));
const StarCommand = lazy(() => import("./pages/StarCommand"));
const PlanDashboard = lazy(() => import("./pages/PlanDashboard"));
const WorkOrder = lazy(() => import("./pages/WorkOrder"));

const APP_BASE_URL = process.env.REACT_APP_BASE_URL;

// Disable StompJS reconnection attempts by setting
// REACT_APP_DISABLE_STOMP_RECONNECT_TIMEOUT in .env.development.local
const STOMP_RECONNECT_TIMEOUT = process.env.REACT_APP_DISABLE_STOMP_RECONNECT_TIMEOUT ? 0 : 5000;

function App() {
  const isAuthenticated = useAuthStore((store) => store.isAuthenticated);
  const setProvider = useAuthStore((store) => store.setProvider);
  const { keycloak } = useKeycloak();
  const { pathname } = useLocation();

  useEffect(() => {
    ApiClient.init();
  }, []);

  useEffect(() => {
    setProvider(keycloak);
  }, [setProvider, keycloak]);

  useEffect(() => {
    if (!keycloak) {
      return;
    }
    keycloak.onTokenExpired = () => {
      window.location.reload();
    };
  }, [keycloak]);

  const isLandingPage = [getLandingRoute(), getForecastRoute()].includes(pathname);

  return (
    <div className="App">
      <ErrorBoundary FallbackComponent={TopLevelErrorBoundaryFallback}>
        <EnvironmentVariablesCheck />
        <ThemeProvider theme={theme}>
          <StompSessionProvider
            url={`${APP_BASE_URL}${notificationUrl(keycloak?.token)}`}
            reconnectDelay={STOMP_RECONNECT_TIMEOUT}
          >
            <QueryClientProvider client={queryClient}>
              <Suspense fallback={<LoadingOverlay />}>
                <Header commentDisabled={!isLandingPage} cutPlanDisabled={!isLandingPage} />
                <Switch>
                  <PublicRoute path={getErrorRoute()} component={GeneralError} exact />
                  <PrivateRoute path={[getAdminRoute()]} component={Admin} role={USER_ROLES.ADMIN} />
                  <Route path={[getLandingRoute(), getForecastRoute()]} component={MapScreen} exact />
                  <Route path={plansRoute} component={PlanDashboard} exact />
                  <Route path={createPlanRoute} component={AddEditPlan} exact />
                  <Route path={`${plansRoute}/:id`} component={AddEditPlan} />
                  <Route path={[getStarCommandRoute()]} component={StarCommand} />
                  <Route
                    path={workOrderRoute}
                    render={(props) => (props.location.state ? <WorkOrder /> : <Redirect to={getLandingRoute()} />)}
                    exact
                  />
                  {isAuthenticated && <Redirect to={getLandingRoute()} />}
                </Switch>
              </Suspense>
            </QueryClientProvider>
          </StompSessionProvider>
        </ThemeProvider>
      </ErrorBoundary>
    </div>
  );
}

const TopLevelErrorBoundaryFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  logError(error);
  resetErrorBoundary();

  return <Redirect to={getErrorRoute()} push={true} />;
};

export default App;
