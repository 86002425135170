import styled from "styled-components";
import { space } from "styled-system";

import { WHITE } from "../../constants/colors";
import { LOADER_OVERLAY } from "../../constants/z-index";

export const LoadingOverlayStyled = styled.div`
  width: 100%;
  height: 100%;
  float: left;
  position: relative;
  z-index: ${LOADER_OVERLAY};
`;

export const LoadingModal = styled.div`
  background-color: var(--colors-teal-1);
  border-radius: 15px;
  color: ${WHITE};
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.27em;
  text-transform: uppercase;
  width: 377px;
  height: 184px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  opacity: 1 !important;
  z-index: ${LOADER_OVERLAY + 1};
  margin: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const LoadingMessage = styled.div`
  position: relative;
  top: -1rem;
`;

export const LoadingWrapper = styled.div`
  ${space}
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background: #020c0d;
  opacity: 0.75;
`;

export const LoaderWrapper = styled.div`
  min-width: 200px;
  width: 33.33%;
  padding: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.3s linear;
`;

export const AnimatedLoader = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  left: -100px;
  animation: shadowRolling 2s linear infinite;

  @keyframes shadowRolling {
    0% {
      box-shadow: 0px 0 transparent, 0px 0 transparent, 0px 0 transparent, 0px 0 transparent;
    }
    12% {
      box-shadow: 100px 0 var(--colors-white), 0px 0 transparent, 0px 0 transparent, 0px 0 transparent;
    }
    25% {
      box-shadow: 110px 0 var(--colors-white), 100px 0 var(--colors-white), 0px 0 transparent, 0px 0 transparent;
    }
    36% {
      box-shadow: 120px 0 var(--colors-white), 110px 0 var(--colors-white), 100px 0 var(--colors-white),
        0px 0 transparent;
    }
    50% {
      box-shadow: 130px 0 var(--colors-white), 120px 0 var(--colors-white), 110px 0 var(--colors-white),
        100px 0 var(--colors-white);
    }
    62% {
      box-shadow: 200px 0 transparent, 130px 0 var(--colors-white), 120px 0 var(--colors-white),
        110px 0 var(--colors-white);
    }
    75% {
      box-shadow: 200px 0 transparent, 200px 0 transparent, 130px 0 var(--colors-white), 120px 0 var(--colors-white);
    }
    87% {
      box-shadow: 200px 0 transparent, 200px 0 transparent, 200px 0 transparent, 130px 0 var(--colors-white);
    }
    100% {
      box-shadow: 200px 0 transparent, 200px 0 transparent, 200px 0 transparent, 200px 0 transparent;
    }
  }
`;
