export type WorkTypeMetadata = {
  priority: number;
  color: string;
};
export const workTypeMap: Record<string, WorkTypeMetadata> = {
  "excavator-existing": { priority: 1, color: "#FDCA14" },
  "excavator-pushout": {
    priority: 2,
    color: "#FDCA14",
  },
  "manual-trim": { priority: 16, color: "#0FFBD1" },
  "mow-existing": { priority: 6, color: "#FF6E40" },
  "spray-high": { priority: 3, color: "#7007B6" },
  "spray-low": { priority: 4, color: "#7007B6" },
  "tree-removal-ash": { priority: 7, color: "#FF33F7" },
  "tree-removal-hazard": {
    priority: 8,
    color: "#FF33F7",
  },
  "tree-removal-large-rural": {
    priority: 9,
    color: "#FF33F7",
  },
  "tree-removal-large-urban": {
    priority: 10,
    color: "#FF33F7",
  },
  "tree-removal-small-rural": {
    priority: 11,
    color: "#FF33F7",
  },
  "tree-removal-small-urban": {
    priority: 12,
    color: "#FF33F7",
  },
  "vine-removal": { priority: 13, color: "#0FFBD1" },
  creek: { priority: 14, color: "#0FFBD1" },
  fence: { priority: 15, color: "#0FFBD1" },
  "mow-pushout": { priority: 5, color: "#FF6E40" },
  placeholder: {
    priority: 20,
    color: "#969696",
  },
};
