import {
  centroid,
  bbox,
  centerOfMass,
  bboxPolygon,
  difference,
  Feature,
  Polygon,
  MultiPolygon,
  booleanPointInPolygon,
  point as pointFn,
  Properties,
  area,
  lineChunk,
  along,
  lineString,
  LineString,
  MultiLineString,
  point,
  buffer,
  angle,
  destination,
  Coord,
} from "@turf/turf";
import L from "leaflet";
import { LatLngBounds } from "leaflet";
import polylabel from "polylabel";

import { RegionsResponse } from "../types/responses";

type CoordinatesPosition = number[][][];

const FeatureFormat = {
  type: "FeatureCollection",
  name: "",
};

const METERS = "meters";

const LINE_STEP = 250;

class MapHelper {
  static normalizeBounds(bounds: number[]): L.LatLngBoundsExpression | null {
    if (!bounds || bounds.length < 4) {
      return null;
    }

    return [
      [bounds[1], bounds[0]],
      [bounds[3], bounds[2]],
    ];
  }

  // TODO fix any types
  static centroid(addFeature: boolean, features: any) {
    const data = addFeature ? { ...FeatureFormat, features: features } : features;

    return centroid(data);
  }

  static centerOfMass(addFeature: boolean, features: any) {
    const data = addFeature ? { ...FeatureFormat, features: features } : features;

    return centerOfMass(data);
  }

  static bBox(addFeature: boolean, features: any) {
    const data = addFeature ? { ...FeatureFormat, features: features } : features;

    return bbox(data);
  }

  static polyMask(bounds: LatLngBounds, regions: RegionsResponse | null): Feature<Polygon | MultiPolygon> | null {
    if (!regions) {
      return null;
    }
    const boxPolygonFromBounds = this.bboxPolygonFromBounds(bounds);

    return difference(boxPolygonFromBounds, regions.feature.geometry as MultiPolygon);
  }

  static isPointInPolygon(
    point: L.LatLng | L.LatLngLiteral | null | undefined,
    bounds: LatLngBounds | null | undefined
  ) {
    if (!bounds || !point) {
      return false;
    }
    const boxPolygonFromBounds = this.bboxPolygonFromBounds(bounds);
    return booleanPointInPolygon(pointFn([point.lng, point.lat]), boxPolygonFromBounds);
  }

  static bboxPolygonFromBounds(bounds: LatLngBounds): Feature<Polygon, Properties | null> {
    return bboxPolygon([
      bounds?.getSouthWest().lng,
      bounds?.getSouthWest().lat,
      bounds?.getNorthEast().lng,
      bounds?.getNorthEast().lat,
    ]);
  }

  static findPolylabel<T extends GeoJSON.Feature>(feature: T) {
    let output: number[] = [];
    if (feature.geometry.type === "GeometryCollection") {
      return output;
    }
    if (feature.geometry.type === "Polygon") {
      output = polylabel(feature.geometry.coordinates as CoordinatesPosition);
    } else {
      let maxArea = 0,
        maxPolygon: CoordinatesPosition = [];
      for (let i = 0, l = feature.geometry.coordinates.length; i < l; i++) {
        const p = feature.geometry.coordinates[i];
        const areaSpace = area({ type: "Polygon", coordinates: p as CoordinatesPosition });
        if (areaSpace > maxArea) {
          maxPolygon = p as CoordinatesPosition;
          maxArea = areaSpace;
        }
      }
      output = polylabel(maxPolygon);
    }
    return output.reverse();
  }

  /**
   * divide line on N chunks
   * @param geoJson
   */
  static getLineChunks<T extends LineString | MultiLineString>(geoJson: T | Feature<T>, step = LINE_STEP) {
    return lineChunk(geoJson, step, { units: METERS });
  }

  /**
   *  return point on center of line
   * @param geoJson
   */
  static getPointsOfLineChunks(geoJson: Feature<LineString>, step = LINE_STEP) {
    return along(geoJson, step / 2, { units: METERS });
  }

  static getAngle(
    endPoint: number[],
    midPoint: number[],
    options?: {
      explementary?: boolean;
      mercator?: boolean;
    }
  ) {
    return angle(midPoint, midPoint, endPoint, options);
  }

  static makePolygonFromPoints(latLngs: Nullable<L.LatLng[]>) {
    if (!latLngs?.length) {
      return null;
    }

    const lineStringFromLatLngs = lineString(latLngs.map((latLng) => [latLng.lng, latLng.lat]));
    const bBox = bbox(lineStringFromLatLngs);
    const polygon = bboxPolygon(bBox);
    const centerCoords = centroid(polygon).geometry.coordinates;

    return {
      polygon: polygon,
      markerPosition: bBox?.length >= 4 ? [bBox[3], bBox[2]] : null,
      bounds: this.normalizeBounds(bBox),
      center: L.latLng(centerCoords[1], centerCoords[0]),
    };
  }

  static getBoundsFromPoint(latLng: number[], bufferInMeters = 100) {
    const pointFrom = point(latLng);
    const bufferFrom = buffer(pointFrom, bufferInMeters, { units: "meters" });
    const bboxFrom = bbox(bufferFrom);
    return this.normalizeBounds(bboxFrom);
  }

  static getDestinationPoint(radiusInMeters: number, origin: Coord, bearing = 90) {
    return destination(origin, radiusInMeters, bearing, { units: "meters" });
  }
}

export default MapHelper;
