import { KeycloakInstance } from "keycloak-js";
import create from "zustand";

import { logError } from "../services";
import AuthHelper from "../utils/authHelper";

export enum USER_ROLES {
  VEGETATION_MANAGER = "vantage_manager",
  ADMIN = "vantage_admin",
}

export type User = {
  name: string | undefined;
  roles: USER_ROLES[] | undefined;
  username: string | undefined;
  email: string | undefined;
};

export type AuthStoreState = {
  user: User | null;
  provider: KeycloakInstance | null;
  isAuthenticated: boolean;
  isAdmin: boolean;
  logOut: () => void;
  setProvider: (provider: KeycloakInstance | null) => void;
  hasRealmRole: (role: string) => boolean;
  getAccessToken: () => string | undefined;
  getRefreshToken: () => string | undefined;
  initUser: () => void;
};

export const logOut = () => {
  AuthHelper.removeUser();
};

export const useAuthStore = create<AuthStoreState>((set, get) => ({
  user: null,
  provider: null,
  isAuthenticated: false,
  isAdmin: false,
  logOut: () => {
    get().provider?.logout();
    logOut();
  },
  setProvider: (provider) => {
    set({ provider, isAuthenticated: !!provider?.authenticated });
    get().initUser();
  },
  hasRealmRole: (role) => {
    return get().provider?.hasRealmRole(role) || false;
  },
  getAccessToken: () => {
    return get().provider?.token;
  },
  getRefreshToken: () => {
    return get().provider?.refreshToken;
  },
  initUser: () => {
    const provider = get().provider;
    if (!provider) {
      return;
    }
    provider
      ?.loadUserProfile()
      .then((userProfile) => {
        const user: User = {
          name: userProfile.username,
          username: userProfile.username,
          roles: provider.realmAccess?.roles as USER_ROLES[],
          email: userProfile.email,
        };
        set({ user, isAdmin: get().hasRealmRole(USER_ROLES.ADMIN) });
      })
      .catch((error) => {
        logError(error);
      });
  },
}));

export default useAuthStore;
