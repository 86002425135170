import create from "zustand";

import { getWorkTypePriority } from "../pages/MapScreen/MapScreen.utils";
import { WorkTypeResponse } from "../types/responses";

export type WorkTypeState = {
  name: string;
  checked: boolean;
  code: string;
};

export type PredominatedWorkTypesStore = {
  selectedWorkTypes: Nullable<Record<string, WorkTypeState>>;
  hidden: boolean;
  visibleWorkTypes: Nullable<string[]>;
  workTypesById: Nullable<Record<string, WorkTypeResponse>>;
  defaultChecked: boolean;
  selectedAll: boolean;
  reloadEnabled: boolean;
  toggleWorkType: (id: string) => void;
  selectAll: () => void;
  toggleHidden: () => void;
  initSelectedWorkTypes: (workTypes: Nullable<WorkTypeResponse[]>) => void;
  getVisibleWorkTypes: () => Nullable<string[]>;
  setVisibleWorkTypes: () => void;
  enableReload: () => void;
  selectOnlyOne: (id: string) => void;
};

const INITIAL_STATE = {
  selectedWorkTypes: null,
  hidden: true,
  spans: null,
  visibleSpans: null,
  visibleWorkTypes: null,
  workTypesById: null,
  defaultChecked: true,
  selectedAll: true,
  reloadEnabled: false,
};

export const sortByPriority = (wt1: WorkTypeResponse, wt2: WorkTypeResponse) => {
  const p1 = getWorkTypePriority(wt1.code);
  const p2 = getWorkTypePriority(wt2.code);
  if (p1 > p2) {
    return 1;
  }

  if (p1 < p2) {
    return -1;
  }

  return 0;
};

export const usePredominatedWorkTypesStore = create<PredominatedWorkTypesStore>((set, get) => ({
  ...INITIAL_STATE,
  toggleWorkType: (id: string) => {
    const selectedWorkTypes = { ...get().selectedWorkTypes };
    if (!selectedWorkTypes?.[id]) {
      return;
    }

    selectedWorkTypes[id].checked = !selectedWorkTypes[id].checked;
    const selected = Object.keys(selectedWorkTypes).filter((key) => selectedWorkTypes[key].checked);
    set({ selectedWorkTypes, selectedAll: selected?.length === Object.keys(get().workTypesById ?? []).length });
    get().setVisibleWorkTypes();
  },
  toggleHidden: () => {
    set({ hidden: !get().hidden });
  },
  selectAll: () => {
    const selectedWorkTypes = { ...get().selectedWorkTypes };
    const selectedAll = !get().selectedAll;
    if (!selectedWorkTypes || !Object.keys(selectedWorkTypes).length) {
      return;
    }
    Object.keys(selectedWorkTypes).forEach((key) => {
      selectedWorkTypes[key].checked = selectedAll;
    });
    set({ selectedWorkTypes, selectedAll });
    get().setVisibleWorkTypes();
  },
  initSelectedWorkTypes: (workTypes) => {
    if (!workTypes) {
      return;
    }
    const previousSelectedWorkTypes = get().selectedWorkTypes;
    const allWorkTypes: Record<string, WorkTypeState> = {};
    const workTypesById: Record<string, WorkTypeResponse> = {};
    workTypes
      .sort(sortByPriority)
      .filter((item) => item.scope === "SPAN")
      .forEach((workType) => {
        const checked = previousSelectedWorkTypes?.[workType.id]
          ? previousSelectedWorkTypes?.[workType.id].checked
          : get().defaultChecked;
        allWorkTypes[workType.id] = { checked: checked, name: workType.name, code: workType.code };
        workTypesById[workType.id] = workType;
      });
    set({ workTypesById, selectedWorkTypes: allWorkTypes });
    get().setVisibleWorkTypes();
  },
  getVisibleWorkTypes: () => {
    const selectedWorkTypes = { ...get().selectedWorkTypes };
    if (!selectedWorkTypes || !Object.keys(selectedWorkTypes).length) {
      return null;
    }
    return Object.keys(selectedWorkTypes)
      .filter((key) => selectedWorkTypes[key].checked)
      .map((key) => key);
  },
  setVisibleWorkTypes: () => {
    const visibleWorkTypes = get().getVisibleWorkTypes();
    set({ visibleWorkTypes });
  },
  enableReload: () => {
    set({ reloadEnabled: true });
    setTimeout(() => {
      set({ reloadEnabled: false });
    }, 100);
  },
  selectOnlyOne: (id) => {
    const selectedWorkTypes = { ...get().selectedWorkTypes };

    Object.keys(selectedWorkTypes).forEach((key) => {
      selectedWorkTypes[key].checked = key === id;
    });
    set({ selectedWorkTypes, selectedAll: false });
    get().setVisibleWorkTypes();
  },
}));
