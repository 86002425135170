export const rgb2hsv = (r: number, g: number, b: number): number[] => {
  const v = Math.max(r, g, b),
    n = v - Math.min(r, g, b);
  const h = n && (v === r ? (g - b) / n : v === g ? 2 + (b - r) / n : 4 + (r - g) / n);
  return [60 * (h < 0 ? h + 6 : h), v && n / v, v];
};

export const hsv2rgb = (h: number, s: number, v: number) => {
  let f = (n: number, k = (n + h / 60) % 6) => v - v * s * Math.max(Math.min(k, 4 - k, 1), 0);
  return [f(5), f(3), f(1)];
};
export const randomBetween = (a: number, b: number) => {
  const min = Math.min(a, b);
  const max = Math.max(a, b);
  return min + (max - min) * Math.random();
};

export const randomizeColor = (firstColor: number[], secondColor: number[]) => {
  const a_hsb: number[] = rgb2hsv(firstColor[0], firstColor[1], firstColor[2]);
  const b_hsb: number[] = rgb2hsv(secondColor[0], secondColor[1], secondColor[2]);
  const between_hsb: number[] = [
    randomBetween(a_hsb[0], b_hsb[0]),
    randomBetween(a_hsb[1], b_hsb[1]),
    randomBetween(a_hsb[2], b_hsb[2]),
  ];
  const rgb = hsv2rgb(between_hsb[0], between_hsb[1], between_hsb[2]);
  return `rgb(${rgb[0]}, ${rgb[1]}, ${rgb[2]})`;
};
